<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="org_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{$t('warehouse_config.organization')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          plain
                          v-model="priceType.org_id"
                          :options="orgList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Type Name (En)" vid="type_name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="type_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('seedsConfig.priceTypeEn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="type_name"
                          v-model="priceType.type_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Type Name (Bn)" vid="type_name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="type_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('seedsConfig.priceTypeBn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="type_name_bn"
                          v-model="priceType.type_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Description (En)" rules="">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('seedsConfig.descriptionEn')"
                        label-for="remarks"
                        slot-scope="{ valid, errors }"
                      >
                      <vue-editor
                      id="description"
                      useCustomImageHandler
                      v-model="priceType.description"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></vue-editor>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  <ValidationProvider name="Description (Bn)" rules="">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('seedsConfig.descriptionBn')"
                      label-for="remarks"
                      slot-scope="{ valid, errors }"
                    >
                    <vue-editor
                    id="description_bn"
                    useCustomImageHandler
                    v-model="priceType.description_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></vue-editor>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { priceTypeStore, priceTypeUpdate } from '../../api/routes'
import { VueEditor } from 'vue2-editor'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor
  },
  created () {
    if (this.id) {
      const tmp = this.getPriceTypeData()
      this.priceType = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      priceType: {
        id: '',
        org_id: this.$store.state.dataFilters.orgId,
        type_name: '',
        type_name_bn: '',
        description: '',
        description_bn: '',
        status: '1'
      }
    }
  },
  computed: {
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      }
  },
  methods: {
    getPriceTypeData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.priceType.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${priceTypeUpdate}/${this.id}`, this.priceType)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, priceTypeStore, this.priceType)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
